import React, { useState } from 'react';
import { useStoreState } from './Store';


const EmptyState = ({ profile, refresh }) => {
  const { email } = profile;
  const { secret, frontContext } = useStoreState();
  const { teammate } = frontContext;
  const [errorMessage, setErrorMessage] = useState(null);
  
  const handleChange = (e) => {
    setErrorMessage(null);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('resume', file);
    formData.append('user', teammate.email);
    formData.append('email', email);
    return fetch(`/api/upload?auth_secret=${secret}`, {
      method: 'POST',
      body: formData,
    })
    .then(r => {
      if (!r.ok)
        throw r;

      refresh();
    })
    .catch(err => {
      console.error(err);
      err.text().then((message) => setErrorMessage(message));
    });
  };

  const attachFile = () => document.getElementById('file-input').click();

  const createProfile = () => {
    setErrorMessage(null);
    fetch(`/api/create?auth_secret=${secret}`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({email, user: teammate.email}),
    })
    .then(r => {
      if (!r.ok)
        throw r;

      refresh();
    })
    .catch(err => {
      console.error(err);
      err.text().then((message) => setErrorMessage(message));
    });
  };

  return <div className="not-found">
    <div className="not-found-email">{email}</div>
    <div className="not-found-button" onClick={createProfile}><div className="lever-icon"></div><span>Create profile</span></div>
    <div className="not-found-resume" onClick={attachFile}>
      <div className="resume-icon"></div>
      <div>Or attach a resume to create a profile.</div>
      <form id="not-found-form">
        <input id="file-input" type="file" onChange={handleChange} />
      </form>
    </div>
    { errorMessage ? (<div className="error-message">{errorMessage}</div>) : undefined }
  </div>;
};

export default EmptyState;