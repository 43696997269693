import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useStoreState } from './Store';
import FlashMessage from './FlashMessage';

const Archive = ({ opportunityId, refresh }) => {
  const { register, handleSubmit } = useForm();
  const { archiveReasons, secret, frontContext } = useStoreState();
  const { teammate } = frontContext;

  const [errorMessage, setErrorMessage] = useState(null);

  const onSubmit = data => {
    if (!data.reason || data.reason === '')
      return;

    const payload = {reason: data.reason, user: teammate.email};

    setErrorMessage(null);
    fetch(`/api/archive/${opportunityId}?auth_secret=${secret}`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload),
    })
    .then((resp) => {
      if (!resp.ok)
        throw resp;

      refresh();
    })
    .catch((err) => {
      console.error(err);
      err.text().then(message => setErrorMessage(message));
    });
  };

  return <>
    { errorMessage ? <FlashMessage message={errorMessage} /> : undefined }
    <form className="archive" onSubmit={handleSubmit(onSubmit)}>
      <select className="archive-select" name="reason" defaultValue={''} ref={register}>
        <option value="" disabled>Pick archive reason</option>
        {archiveReasons.map(r => <option key={r.id} value={r.id}>{r.reason}</option>)}
      </select>
      <button className="archive-submit" type="submit"><div className="archive-icon"></div><span>Archive candidate</span></button>
    </form>
  </>;
};

export default Archive;