import React, { useContext } from 'react';
import { StoreContext } from './Store';

const Archived = ({ profile }) => {
  const { firstName, lastName, email, phone, leverUrl, linkedinUrl } = profile;
  const { state } = useContext(StoreContext);
  const { openUrl, createDraft } = state.frontContext;

  return <>
    <div className="archived">
      <div className="archived-name">{firstName} {lastName}</div>
      <div className="archived-notice">(Archived)</div>
      <div className="archived-email">{email}</div>
      { phone ? (<div className="archived-phone">{phone}</div>) : undefined }
      <div className="action-buttons">
        <div className="draft-email" onClick={() => createDraft({to: [email]})}>Email {firstName}</div>
        { leverUrl ? (<div className="to-lever" onClick={() => openUrl(leverUrl)}></div>) : undefined }
        { linkedinUrl ? (<div className="to-linkedin" onClick={() => openUrl(linkedinUrl)}></div>) : undefined }
      </div>
    </div>
    <hr />
  </>;
};

export default Archived;