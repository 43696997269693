import React, { useState, useEffect } from 'react';

const FlashMessage = ({ message }) => {
  const [visible, setVisible] = useState(true);
  const delay = 5000;

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, delay);

    return () => clearTimeout(timer);
  }, [message]);

  if (!visible)
    return <></>;

  return <div className="error-flash">{message}</div>;
};

export default FlashMessage;