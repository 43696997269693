import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useStoreState, useStoreDispatch } from './Store.js';

const Credentials = ({ redirect, requirements }) => {
  const { secret } = useStoreState();
  const dispatch = useStoreDispatch();

  let history = useHistory();
  let location = useLocation();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {if (redirect) history.push('/main')}, [redirect, history]);

  const { register, handleSubmit } = useForm();

  const onSubmit = data => {
    console.log(data);

    setIsLoading(true);
    setErrorMessage(null);
    fetch(`/credentials?auth_secret=${secret}`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    })
    .then((resp) => {
      if (!resp.ok)
        throw resp;

      return resp.json();
    })
    .then((data) => {
      dispatch({type: 'credentials_set', value: {...data}});
      history.push('/main');
    })
    .catch((err) => {
      console.log(err);
      setIsLoading(false);
      err.text().then(message => setErrorMessage(message));
    });
  };

  return <>
    <div className="credentials">
      <form onSubmit={handleSubmit(onSubmit)}>
        {
          requirements.map(req => <label key={req.key}>
            <div className="label">{req.label}</div>
            <div className="field"><input type="text" name={req.key} ref={register} /></div>
          </label>
          )
        }
        <input type="submit" className={isLoading ? 'submit-loading' : ''} disabled={isLoading} value={isLoading ? 'Updating...' : 'Save'} />
        {location.pathname !== '/' ? (
          <span className="close-button">
            <Link to="/main">Close</Link>
          </span>
        ) : ''}
        {errorMessage ? <div className="error-message">{errorMessage}</div> : ''}
      </form>
    </div>
  </>;
};

export default Credentials;