import React, { useState } from 'react';
import { useStoreState } from './Store';
import FlashMessage from './FlashMessage.js';

const Role = ({ opportunityId, position, hiringManager, owner, stage }) => {
  const { stages, secret, frontContext } = useStoreState();
  const { teammate } = frontContext;

  const [currentStage, setCurrentStage] = useState(stage);
  const [errorMessage, setErrorMessage] = useState(null);

  const updateStage = data => {
    if (!data.target || !data.target.value)
      return;

    const stageId = data.target.value;
    const payload = {stage: stageId, user: teammate.email};

    setErrorMessage(null);
    fetch(`/api/stage/${opportunityId}?auth_secret=${secret}`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload),
    })
    .then((resp) => {
      // Optimistically display the stage
      if (!resp.ok)
        throw resp;

      setCurrentStage(stages.find(s => s.id === stageId)?.name);
    })
    .catch((err) => {
      console.error(err);
      err.text().then(message => setErrorMessage(message));
    });
  };

  return <div className="role">
    { errorMessage ? <FlashMessage message={errorMessage} /> : undefined }
    <h3>Role</h3>
    { position ? (
      <div className="role-category">
        <div className="role-category-title">Position</div>
        <div className="role-category-value">{position}</div>
      </div>
    ) : undefined }
    { hiringManager ? (
      <div className="role-category">
        <div className="role-category-title">Hiring Manager</div>
        <div className="role-category-value">{hiringManager}</div>
      </div>
    ) : undefined }
    {owner ? (
      <div className="role-category">
        <div className="role-category-title">Owner</div>
        <div className="role-category-value">{owner}</div>
      </div>
    ) : undefined }
    {currentStage ? (
      <div className="role-category">
        <div className="role-category-title">Stage</div>
        <select className="stage-select" name="stage" defaultValue={stages.find(s => s.name === currentStage) && stages.find(s => s.name === currentStage).id} onChange={updateStage}>
          {stages.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
        </select>
      </div>
    ) : undefined }
  </div>;
};

export default Role;