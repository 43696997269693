import React, { useContext } from 'react';
import Role from './Role.js';
import Interviews from './Interviews.js';
import Notes from './Notes.js';
import Archive from './Archive.js';
import { StoreContext } from './Store.js';

const Person = ({ firstName, lastName, email, phone, leverUrl, linkedinUrl }) => {
  const { state } = useContext(StoreContext);
  const { openUrl, createDraft } = state.frontContext;

  return <div className="candidate">
    <div className="candidate-name">{firstName} {lastName}</div>
    <div className="candidate-email">{email}</div>
    { phone ? (<div className="candidate-phone">{phone}</div>) : undefined }
    <div className="action-buttons">
      <div className="draft-email" onClick={() => createDraft({to: [email]})}>Email {firstName}</div>
      { leverUrl ? (<div className="to-lever" onClick={() => openUrl(leverUrl)}></div>) : undefined }
      { linkedinUrl ? (<div className="to-linkedin" onClick={() => openUrl(linkedinUrl)}></div>) : undefined }
    </div>
  </div>;
};

const Candidate = ({ profile, refresh }) => {
  const {
    email, opportunityId, firstName, lastName,
    phone, linkedinUrl, leverUrl,
    position, owner, hiringManager, stage,
    interviews, notes
  } = profile;

  return <div>
    <Person firstName={firstName} lastName={lastName} email={email} phone={phone} leverUrl={leverUrl} linkedinUrl={linkedinUrl} />
    <hr />
    <Role position={position} hiringManager={hiringManager} owner={owner} stage={stage} opportunityId={opportunityId} />
    <hr />
    <Interviews interviews={interviews} />
    <hr />
    <Notes notes={notes} opportunityId={opportunityId} />
    <hr />
    <Archive opportunityId={opportunityId} refresh={refresh} />
  </div>;
};

export default Candidate;