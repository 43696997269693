import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { useStoreState } from './Store.js';
import Credentials from './Credentials.js';
import Main from './Main.js';
import FlashMessage from './FlashMessage.js';

const Application = () => {
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => setShowMenu(!showMenu);
  const { secret, shouldRequireConfig, requirements, frontContext } = useStoreState();
  const { type, conversation } = frontContext;
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const refreshData = () => {
    if (isRefreshing)
      return;

    setIsRefreshing(true);
    setErrorMessage(null);
    fetch(`/api/refresh?auth_secret=${secret}`, {
      method: 'GET',
      credentials: 'same-origin',
    })
    .then((resp) => {
      if (resp.status >= 300)
        throw resp;

      setIsRefreshing(false);
    })
    .catch((err) => {
      console.error(err);
      setIsRefreshing(false);
      err.text().then((message) => setErrorMessage(message));
    });
  };

  return <div className="app">
    { errorMessage ? <FlashMessage message={errorMessage} /> : undefined }
    <Router>
      { shouldRequireConfig ? undefined : (<div className="menu-gear" onClick={toggleMenu}></div>) }
      { showMenu ? (
        <ul className="menu-links">
          <li onClick={toggleMenu}><Link to="/credentials">Edit credentials</Link></li>
          <li><button className="link" onClick={refreshData}>{isRefreshing ? 'Refreshing...' : 'Refresh data'}</button></li>
        </ul>
      ) : undefined}
      <Switch>
        <Route exact path="/">
          {/* If a valid config is not required, we can redirect to the main view */}
          <Credentials requirements={requirements} redirect={!shouldRequireConfig} />
        </Route>
        <Route path="/credentials">
          <Credentials requirements={requirements} />
        </Route>
        <Route path="/main">
          <Main conversationType={type} email={conversation?.recipient?.handle} />
        </Route>
      </Switch>
    </Router>
  </div>;
};

export default Application;