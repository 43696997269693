import React, { createContext, useContext, useReducer, useEffect, useMemo } from 'react';
import { contextUpdates } from '@frontapp/plugin-sdk';

export const StoreContext = createContext(null);

export const useStoreState = () => {
  const { state } = useContext(StoreContext);
  return state;
};

export const useStoreDispatch = () => {
  const { dispatch } = useContext(StoreContext);
  return dispatch;
};

const stateReducer = (oldState, action) => {
  console.log(action);

  if (action.type === 'boot_data_received') {
    const { value } = action;

    return {
      secret: value.secret,
      // If config isn't valid then require a valid config
      shouldRequireConfig: !value.isValidConfig,
      requirements: value.requirements,
      archiveReasons: value.archiveReasons,
      stages: value.stages,
    };
  }

  if (action.type === 'credentials_set') {
    const { value } = action;

    return {
      ...oldState,
      shouldRequireConfig: false,
      archiveReasons: value.archiveReasons,
      stages: value.stages,
    };
  }

  if (action.type === 'new_context_received')
    return {...oldState, frontContext: action.value};

  return oldState;
};

export default ({ children }) => {
  const [state, dispatch] = useReducer(stateReducer, null);
  const hasBooted = Boolean(state?.requirements);

  useEffect(() => {
    // The auth_secret is used for authentication of the plugin
    const secret = (new URL(document.location.href)).searchParams.get('auth_secret') || localStorage.getItem('auth_secret');
    console.log(`Secret is ${secret}`);

    if (secret !== 'null')
      localStorage.setItem('auth_secret', secret);

    // Fetch boot data
    // FIXME: abort fetch on component unmount
    fetch(`/boot?auth_secret=${secret}`, {
      method: 'GET',
      credentials: 'same-origin',
    })
    .then((r) => r.json())
    .then((data) => dispatch({type: 'boot_data_received', value: {secret, ...data}}))
    .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!hasBooted)
      return undefined;

    // Mostly boilerplate code to get Front's context
    // Subscribe to updates of Front's context
    const subscription = contextUpdates.subscribe(newContext => dispatch({type: 'new_context_received', value: newContext}));
    return () => subscription.unsubscribe();
  }, [hasBooted]);

  const storeContextValue = useMemo(() => ({
    state,
    dispatch
  }), [state, dispatch]);

  if (!hasBooted || !state?.frontContext)
    return null;

  return <StoreContext.Provider value={storeContextValue}>
    {children}
  </StoreContext.Provider>;
};