import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';
import { useStoreState } from './Store';
import FlashMessage from './FlashMessage';

const Note = ({ user, createdAt, text }) => {
  return <li className="note">
    <div><strong className="note-user">{user}</strong> <span className="note-date">{DateTime.fromMillis(createdAt).toLocaleString(DateTime.DATETIME_SHORT)}</span></div>
    <div className="note-text">{text}</div>
  </li>;
};

const NoteList = ({ notes }) => {
  return <ul className="notes-list">
    {notes.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1).map(m => <Note key={m.createdAt} user={m.user} createdAt={m.createdAt} text={m.text} />)}
  </ul>;
};

const Notes = ({ notes, opportunityId }) => {
  const [notesList, setNotesList] = useState(notes);
  const { register, handleSubmit, reset } = useForm();
  const { secret, frontContext } = useStoreState();
  const { teammate } = frontContext;

  const [errorMessage, setErrorMessage] = useState(null);

  const onSubmit = data => {
    const payload = {note: data.note, opportunity: opportunityId, user: teammate.email};

    setErrorMessage(null);
    fetch(`/api/note?auth_secret=${secret}`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload),
    })
    .then((resp) => {
      reset();
      // Optimistically display the note
      if (!resp.ok)
        throw resp;

      setNotesList([...notesList, {
        user: teammate.name,
        text: data.note,
        createdAt: Date.now(),
      }]);
    })
    .catch((err) => {
      console.error(err);
      err.text().then(message => setErrorMessage(message));
    });
  };

  return <>
    { errorMessage ? <FlashMessage message={errorMessage} /> : undefined }
    <div className="notes">
      <h3>Notes</h3>
      {notesList.length === 0 ? undefined : <NoteList notes={notesList} />}
      <div>
        <form className="add-note" onSubmit={handleSubmit(onSubmit)}>
          <textarea placeholder="Add note" name="note" ref={register} rows="3" />
          <input className="add-note-submit" type="submit" value="Add note" />
        </form>
      </div>
    </div>
  </>;
};

export default Notes;