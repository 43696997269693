import React, { useState, useEffect } from 'react';
import { useStoreState } from './Store.js';
import EmptyState from './EmptyState.js';
import Archived from './Archived.js';
import Candidate from './Candidate.js';

const Loading = () => <div className="loading">Loading...</div>;
const NOTIFICATIONS_EMAIL = 'no-reply@hire.lever.co';

const Main = ({ conversationType, email }) => {
  const { secret, frontContext } = useStoreState();
  const [userProfile, setUserProfile] = useState(null);
  const [lastCalledUri, setLastCalledUri] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);
  
  const [screenState, setScreenState] = useState('loading');

  const isValidEmail = str => str === NOTIFICATIONS_EMAIL || (str.split('@').length > 1 && str.split('.').length > 1);
  
  useEffect(() => {
    // If there's no conversation, or nothing has changed, do nothing
    if (!email || (email !== NOTIFICATIONS_EMAIL && !forceRefresh && email === userProfile?.email))
      return;

    const getLatestMessage = () => frontContext.listMessages().then(r => r.results.pop());
    const getCandidateIdFromMessage = (message) => {
      const partsCandidate = message.content?.body?.split('https://hire.lever.co/candidates/');
      if (!partsCandidate || partsCandidate.length === 1)
        return null;
      
      // Whether it ends on the ID or is followed by a /, we cut
      const candidateId = partsCandidate[1].split('"')[0].split('/')[0];
      return candidateId;
    };

    const fetchData = async () => {
      let uri = `/api/fetch/email/${email}`;
      
      // Different paths whether it's a notification email or a human-sent email
      if (email === NOTIFICATIONS_EMAIL) {
        const latestMessage = await getLatestMessage();
        const candidateIdFromMessage = latestMessage ? getCandidateIdFromMessage(latestMessage) : null;
        
        if (!candidateIdFromMessage) {
          setScreenState('no_email')
          setLastCalledUri(null);
          return setUserProfile(null);;
        }

        uri = `/api/fetch/id/${candidateIdFromMessage}`;
      }

      // No point in calling again the same URI
      if (uri === lastCalledUri && !forceRefresh)
        return;

      const isForceRefreshing = forceRefresh;
      setForceRefresh(false);
      setScreenState('loading');
      setLastCalledUri(uri);
      fetch(`${uri}?auth_secret=${secret}`, {
        method: 'GET',
        credentials: 'same-origin',
      })
      .then(r => {
        if (r.status >= 200 && r.status < 300)
          return r.json();

        throw new Error(r.status);
      })
      .then(data => {
        const nameParts = data.name.split(' ');
        const firstName = nameParts[0];
        const lastName = data.name.substring(nameParts[0].length + 1);
  
        const profile = {
          candidateId: data.candidateId,
          email: data.email,
          phone: data.phone,
          firstName: firstName,
          lastName: lastName,
          leverUrl: `https://hire.lever.co/candidates/${data.candidateId}`,
        };

        // If this is actually the same user, don't update
        if (!isForceRefreshing && profile.email === userProfile?.email)
          return;
  
        if (data.isArchived) {
          setUserProfile(profile);
          return setScreenState('archived');
        }

        const fullProfile = {
          ...profile,
          opportunityId: data.currentOpportunityId,
          linkedinUrl: data.linkedinUrl,
          position: data.position,
          owner: data.owner,
          hiringManager: data.hiringManager,
          stage: data.stage,
          interviews: data.interviews
            .map(m => ({
              ...m,
              upcoming: m.date > Date.now()
            }))
            .sort((a, b) => a.date < b.date ? 1 : -1),
          notes: data.notes
            .sort((a, b) => a.createdAt < b.createdAt ? 1 : -1),
        };

        setUserProfile(fullProfile);
        return setScreenState('active');
      })
      // Only error we handle is 404 for now
      .catch(err => {
        setForceRefresh(false);
        setUserProfile({email: email});
        return setScreenState('not_found');
      });
    };

    fetchData();
  }, [email, secret, userProfile, frontContext, lastCalledUri, forceRefresh, screenState]);

  if (conversationType !== 'singleConversation')
    return <div className="notice">This plugin only works on single conversations.</div>;

  if (!email || !isValidEmail(email) || screenState === 'no_email')
    return <div className="notice">No email address found.</div>;

  if (screenState === 'loading')
    return <Loading />;

  if (screenState === 'not_found')
    return <EmptyState profile={userProfile} refresh={() => setForceRefresh(true)} />;

  if (screenState === 'archived')
    return <Archived profile={userProfile} />;

  if (screenState === 'active')
    return <Candidate profile={userProfile} refresh={() => setForceRefresh(true)} />;

  return <>Lol<Loading /></>;
};

export default Main;