import React from 'react';
import { DateTime } from 'luxon';

const Interview = ({ id, type, interviewers, date, where, gcal, short }) => {
  return <li>
    <div className="interviews-category-value interview-type">
      <a target="_blank" rel="noreferrer noopener" href={`https://hire.lever.co/interviews/${id}`}>{type}</a>
      <div>{DateTime.fromMillis(date).toLocaleString(short ? DateTime.DATE_SHORT : DateTime.DATETIME_SHORT)}</div>
      <div>{interviewers.join(', ')}</div>
    </div>
  </li>;
};

const InterviewList = ({ interviews, short }) => {
  if (interviews.length === 0)
    return <span>None</span>;

  return <ul className="interviews-list">
    {interviews.map(m => <Interview short={short} key={m.id} id={m.id} type={m.type} interviewers={m.interviewers} date={m.date} where={m.where} gcal={m.gcal} />)}
  </ul>
};

const Interviews = ({ interviews }) => {

  return <div className="interviews">
    <h3>Interviews</h3>
    <div className="interviews-category">
      <div className="interviews-category-title"><strong>Upcoming</strong></div>
      <div className="interviews-category-value">
        <InterviewList interviews={interviews.filter(m => m.upcoming)} />
      </div>
    </div>
    <div className="interviews-category">
      <div className="interviews-category-title"><strong>Past</strong></div>
      <div className="interviews-category-value">
        <InterviewList interviews={interviews.filter(m => !m.upcoming)} short={true} />
      </div>
    </div>
  </div>;
};

export default Interviews;